<template>
    <promo-landing-page
        image-path-in-assets="images/pages/promo/netflixLogo.jpg"
        image-width="231"
        title="Get 3 Months Free at Netflix"
        message="Aven is offering 3 months of free Netflix on your Aven card as statement&nbsp;credit."
        :cta-text="$t('global.cta.gotIt')"
        :on-cta-click="returnToRoot"
    />
</template>

<script>
    import PromoLandingPage from '@/views/promo/PromoLandingPage'
    import { navigation } from '@/mixins/navigation'

    export default {
        name: 'PromoNetflixFirst3MonthsFree',
        components: {
            'promo-landing-page': PromoLandingPage,
        },
        mixins: [navigation],
    }
</script>

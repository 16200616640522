<template>
    <div class="container text-center">
        <img
            :src="require(`../../assets/${this.imagePathInAssets}`)"
            :width="imageWidth"
            :alt="title"
            class="mt-4"
        >
        <h5
            v-html="title"
            class="mt-5"
        />
        <p
            v-html="message"
            class="mt-2"
        />
        <div class="d-grid">
            <button
                class="btn btn-primary mt-4"
                @click="onCtaClickInternal"
            >
                {{ ctaText }}
            </button>
        </div>
        <div class="d-grid">
            <a
                v-if="onDismissClick"
                class="btn btn-block mt-1"
                @click="onDismissClickInternal"
            >{{ dismissText }}</a>
        </div>
    </div>
</template>

<script>
    import { i18n } from '@/utils/i18n'

    export default {
        name: 'PromoLandingPage',
        props: {
            imagePathInAssets: {
                type: String,
                required: true,
            },
            imageWidth: {
                type: Number,
                required: false,
                default: 241,
            },
            title: {
                type: String,
                required: true,
            },
            message: {
                type: String,
                required: true,
            },
            ctaText: {
                type: String,
                required: true,
            },
            dismissText: {
                type: String,
                required: false,
                default: i18n.t('global.cta.dismiss'),
            },
            onCtaClick: {
                type: Function,
                required: true,
            },
            onDismissClick: {
                type: Function,
                required: false,
            },
        },
        mounted: function () {
            this.$logEvent('view_promo_landing_page')
        },
        methods: {
            onCtaClickInternal: function () {
                this.$logEvent('click_promo_landing_page_cta')
                this.onCtaClick()
            },
            onDismissClickInternal: function () {
                this.$logEvent('click_promo_landing_page_dismiss')
                this.onDismissClick()
            },
        },
    }
</script>
